import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import styled from '@emotion/styled';
import kebabcase from 'lodash.kebabcase';
import theme from '../utils/theme';

const Container = styled.div`
  ${props => props.customCss}
  position: relative;
  width: 100%;
  padding: 0rem 1rem 0.5rem 1rem;
  color: ${theme.dark.fgColor};

  & h3 {
    color: ${theme.dark.fgColor};
    margin-top: 0;
  }
`;

const Tags = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`;

const Tag = styled(Link)`
  text-shadow: none;
  background-image: none;
  font-size: 0.6rem;
  padding: 0.3rem 0rem 0.3rem 0.3rem;
  margin: 0.25rem;

  .tag-text {
    padding: 0.3rem 0.3rem 0.3rem 0.3rem;

    background-color: ${theme.term.yellow};
    color: ${theme.term.black};
  }

  .tag-counter {
    padding: 0.3rem 0.3rem 0.3rem 0.3rem;
    background-color: ${theme.term.black};
    color: ${theme.dark.fgColor};
  }

  &:hover {
    .tag-text {
      background-color: #f5a810;
    }

    .tag-counter {
      background-color: ${theme.dark.fgColor};
      color: ${theme.term.black};
    }
  }
`;

export { Tag };

const TagCloud = props => {
  let { title, customCss, data, ...otherProps } = props;
  title = title || 'Tags';

  return (
    <Container customCss={customCss}>
      {title && <h3>{title}</h3>}
      <Tags {...otherProps}>
        {data.map((item, i) => (
          <Tag key={i} title={item.tag} to={`/tags/${kebabcase(item.tag)}`}>
            <span className="tag-text">{item.tag}</span>
            <span className="tag-counter">{item.count}</span>
          </Tag>
        ))}
      </Tags>
    </Container>
  );
};

TagCloud.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      tag: PropTypes.string.isRequired,
      count: PropTypes.number.isRequired,
    })
  ).isRequired,
};

export default TagCloud;
